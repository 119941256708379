/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/*all tag*/
* {
  margin: 0;
  padding: 0;
  font-family: "Sarabun", "sans-serif" ;
}

.dummy{
  width: 20px;
  height: 2vh;
}
.dummy2{
  width: 20px;
  height: 7vh;
}

/*main container*/
.container_home {
  background-color: #161616;
  color: white;
  height: auto;
  overflow: hidden;
  /* overflow-x: none; */
}

/*help header access sticky top =0   parent*/
.container__header2 {
  top: 0px;
  margin: 0px;
  /* position: absolute; */
  /* height: 1300vh; */
  width: 100%;
  z-index: 99;
  /* margin-top: 60px; */
  /* background-color: wheat; */
}
.home2__cotainer__scroll{
  overflow: scroll;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  scrollbar-color:white;
}
.home2__cotainer__scroll2{
  overflow: scroll;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  scrollbar-color:white;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Section 1  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.machineLayout__container {
  display: flex;
  justify-content: center;
  height:  calc(100vh - 60px);
  align-items: center;
  /* background-color: red; */
  width: 100%;
}
.machineLayout__container2{
  /* display: flex;
  justify-content: center; */
  align-items: center;
  width: 100%;
}

/*text showdown ด้านล่าง*/
.machineLayout__text-container{
  display: flex;
  background-color: rgba(0,0,0,0.5);
  height: 35vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  vertical-align: middle;
}

.machineLayout__text-container >div{
  cursor: pointer;
  width: 15%;
  height: 20vh;
  background-color: rgba(39, 170, 23, 0.7);
  position: absolute;
  right: 10%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.machineLayout__text-container >div >h2{
  font-size: 3vmin;
  line-height: 4vmin;
}
.machineLayout__text-container >div >h1{
  font-size: 5vmin;
}
/*text style*/
.machineLayout__text-container>h1:nth-child(1){
    color: white;
    font-size: 14vmin;
    font-weight: bold;
    line-height: 17vmin;
    margin-top: -2vh;
}

.machineLayout__text-container>h1:nth-child(2){
    color: white;
    font-size: 7vmin;
    font-weight: bold;
    line-height: 1vmin;
}

.machineLayout__text-container>h1:nth-child(3){
    color: white;
    font-size: 4.5vmin ;
    font-weight: normal;
    line-height: 9vmin;
}

.machineLayout_image {
  width: 65%;
}


@media screen  and (max-width: 480px) {
  .machineLayout__text-container >div{
    display: none;
  }
  .machineLayout_image {
    width: 95%;
    margin-top: -15vh;
  }
  .machineLayout__text-container{
    height: 20vh;
  }
} 
/*######################### Section 1  #################################*/


/*##############################    section 2 ย่อทั้งโรงงานให้อยู่ในมือคุณ      #########################################*/
.factoryLayout__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    width: calc(100% - 100px);
    background-color:  #161616;
    text-align: left !important;
    padding-left: 50px;
    padding-right: 50px;
    
}
.factoryLayout__container-text{
  /* background-color: wheat; */
  text-align: left !important;
  margin-left: 10%;
  margin-top: -50vh;
}
.factoryLayout__text {
 
  font-size: 8.4vmin;
  line-height: 13.7vmin;

  font-weight: bold;
  color: white;
}
.factoryLayout__factory{
  width: 50%;
}
@media only screen and (max-width: 680px) {
  .factoryLayout__container {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .factoryLayout__container-text{
    /* background-color: rgb(88, 137, 241); */
    margin-top: 0px;
  }
  .factoryLayout__factory {
    width: 100%;
    /* background-color: aqua; */
  }

}
/*##############################    section 2 ย่อทั้งโรงงานให้อยู่ในมือคุณ      #########################################*/


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 3 show video  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.video-machine-layout__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  width: 100%;
  background-color:  #161616;
  text-align: center;
}
.wrapText{
  display: flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  width: 80%;
  text-align: center;
  height: 20vh;
  top: 0px;
  /* background-color: wheat; */

}
.video-machine-layout__font{
  position: absolute;
  font-size: 4.1vmin;
  z-index: 2;
  line-height: 7.5vmin;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2em;


}
.video-machine-layout__video{
  width: 100%;
}
@media only screen and (max-width: 620px) {
  .container__10__video{
    height: 80vh;
    object-fit: cover;
    margin-bottom: 50px;
  }
  .video-machine-layout__video{
    height: 55vh;
    object-fit: cover;
  }
  .video-machine-layout__font{
    position: absolute;
    font-size: 6.1vmin;
    margin-top: 0px;
  }

  .wrapText{
    align-items: flex-end;
    margin-top: 30vh;
  }

  .dummy33{
    width: 50;
    height: 30vh;
  }
  .video-machine-layout__video{
    margin-top: 50vh;
  }
}



/*##############################    section 3 show video       #########################################*/


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 4 show text ต่อจาก video  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

/* section 4 */
.report1__container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100vh - 60px);
  align-items: center;
  width: 100%;
  background-color: #161616;
  text-align: center;
}
.report1__text{
  font-size: 7.4vmin;
  line-height: 7.5vmin;
  font-weight: bold;
}
.report1__image{
  width: 60%;
}
@media only screen and (max-width: 480px) {
  .report1__image{
    width: 95%;
  }
  .report1__text{
    font-size: 7.4vmin;
    line-height: 15vmin;
    font-weight: bold;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
/*##############################    section 4 show text ต่อจาก video       #########################################*/


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 5   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.container_5 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100vh - 60px);
  align-items: center;
  width: 100%;
  background-color: #161616;
}
.container_5__text{
  font-size: 4.8vmin;
  font-weight: normal;
  line-height: 7vmin;
  margin-top: 10vh;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
}
.text2{
  margin-top: 0px;
}
.container_5_picture{
  width: 85%;
  margin-bottom: -40vh;
}
@media screen and (max-width: 960px) {
  .container_5_picture{
    width: 80%;
  }
}
@media screen and (max-width: 750px) {
  .container_5_picture{
    width: 98%;
  }
}
@media screen and (max-width: 620px){
  .container_5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh);
    align-items: center;
    width: 100%;
    /* background-color: #e6bdbd; */
  }
  .container_5_picture{
    width: 95%;
    margin-bottom: 0px;
  }
  .container_5__text{
    font-size: 5vmin;
    font-weight: bold;
    line-height: 9vmin;
    margin-top: -5vh;
    padding-bottom: 10vh;
    
  }

}
/*##############################    section 5   #########################################*/


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 6   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.container_6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  width: 100%;
  background-color: #161616;
  text-align: center;
}
.container_6__text{
  font-size: 4.5vmin;
  font-weight: normal;
  line-height: 8vmin;
  z-index: 3;
  margin-bottom: 72vh;
  position: absolute;
}
.fullscreen_section6 {
  width: 100%;
}
@media screen and (max-width: 480px) {
  .container_6__text{
    margin-bottom: 30vh;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 6.1vmin;
    font-weight: bold;
  }
  .video-machine-layout__video_section6{
    background-color: blueviolet;
    height: 50vh;
    object-fit: cover;
  }
}

/*##############################    section 6   #########################################*/

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 7   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.container_7 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100vh - 60px);
  align-items: center;
  width: 100%;
  background-color: #161616;
  text-align: center;
}
.container_7__text{
  font-size: 4.8vmin;
  font-weight: normal;
  line-height: 7vmin;
  margin-top: 10vh;
}
.container_7__picture{
  width: 70%;
}
@media screen and (max-width: 480px) {

  .container_7__picture{
    width: 95%;
    margin-bottom: 10vh;
  }
  .container_7__text{
    font-size: 6.1vmin;
    font-weight: bold;
    line-height: 10vmin;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .container_7{
  justify-content: center;
  
  }
  .dummy5{
    width: 50px;
    height: 10vh;
  }
}
/*##############################    section 7   #########################################*/

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 8   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.container_8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 60px);
  align-items: center;
  width: calc(100vw -40px);
  background-color: #161616;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.container_8_picture{
  width: 35%;
}
.container__wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.container_text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 55%;
  height: 70vh;
  text-align: left;
}
.container_8__text-header{
  font-size: 4.8vmin;
  font-weight: normal;
  line-height: 7.8vmin;
  text-align: center;

}
.container_8__text-topic{
  font-size: 3vmin;
  font-weight: normal;
  line-height: 5vh;
  line-height: 1.8em;
}
.container_8__text-detail{
  font-size: 2.5vmin;
  font-weight: normal;
  color: #808080;
  line-height: 1.4em;
  text-align: left !important;

}
.container_8__text-note{
  font-size: 2vmin;
  font-weight: normal;
  color: #808080;
  line-height: 1.4em;
  text-align: left !important;
  

}
.container_8__img-show{
  width:  100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;

}

@media only screen and (max-width: 480px) {
  .container_8 {
    text-align: left !important;
  }
  .container__wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: left !important;
  }
  .container_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 55%;
    height: 40vh;
    text-align: left !important;
  }
  .container_text{
    width: 100%;
    text-align: left !important;
  }
  .container_8_picture{
    width: 95%;

  }
  .container_8__text-header{
    font-size: 4vmin;
    font-weight: normal;
    line-height: 7vmin;
    margin-bottom:5vh;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .dummy6{
    width: 50;
    height: 20vh;
  }
}
/*##############################    section 8   #########################################*/

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 9   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.container_9 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100vh - 60px);
  align-items: center;
  width: 100%;
  background-color: #161616;
  /* background-color: #b8a9a9; */
  text-align: center;
}
.container_9_text-header{
  position: absolute;
  top: 10vh;
  font-size: 4.8vmin;
  line-height: 1.7em;
}
.container_9_text{
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-right: 10vw;
  margin-top: 5vh;
}

.container_9_picture{
  width: 40%;
}

.container_9__text-detail{
  position: absolute;
  bottom: 10vh;
  width: 60vw;
  left: 35vw;
}
.container_9__text-detail>p{
  color: #b3b1b1;
  font-size: 2vmin;
  text-align: left;
  line-height: 4.2vmin;
}
@media screen and (max-width: 960px) {
  .container_9_picture{
    width: 70%;
  }
}
@media screen and (max-width: 750px) {
  .container_9_picture{
    width: 80%;
  }
}
@media screen and (max-width: 520px) {
  .container_9__text-detail p {
    font-size: 3vmin;
    line-height: 5vmin;
  }
  .container_9_picture{
    width: 20;
    margin-top: -20px;
  }
  .container_9 {
    justify-content: center;
    align-items: center;
  }
  .container_9_text-header{
    top: 20vh;
  }
  .container_9__text-detail{
    position: absolute;
    bottom: 10vh;
    width: 60vw;
    left: 25vw;
  }
}

/*##############################    section 9   #########################################*/


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 10   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.container_10 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 40px;
  height: calc(100vh - 60px);
  align-items: center;
  width: 100%;
  background-color: #161616;
  text-align: center;
}
.container_10__text{
  font-size: 4vmin;
  font-weight: normal;
  line-height: 7vmin;
  z-index: 2;
  /* color: #d48686; */
  position: absolute;
}

.dummy10{
  height: 100px;
  width: 100px;
  /* background-color: wheat; */
}
.text1{
  margin-top: -60vh;
}
.container__10__video{
  width: 60%;
}
@media only screen and (max-width: 520px) {
  .container_10{
    /* background-color: blue; */
  }
  .container__10__video{
    width: 100%;
    position: absolute;
  }
  .button_safari_10{
    margin-top: 20vh;
    position: absolute;
  }

}

/*##############################    section 10   #########################################*/

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> section 11   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.container__11 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100vh - 60px);
  align-items: center;
  width: 100%;
  background-color: #161616;
  text-align: center;
}
.container__11_2{
  width: 70%;
  display: flex;
  flex-direction: row;
  /* background-color: khaki; */
  padding-left: 150px;
  padding-right: 150px;

}
.container__11_2 div:nth-child(1){
  /* background-color :red; */
  align-self: right;

}
h4{
  color: white;
}
.container__11_text-header{
  font-size: 5vmin;
  font-weight: bold;
}
.container__11_text-right{
  font-size: 4vmin;

}
.container__11_picture{
  width: 50%;
  margin-bottom: 50px;
}
.container__11_dummy3{
  width: 0px;
}
@media only screen and (max-width: 480px) {
  .container__11_2{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* background-color: red; */
    height: 70%;
  }
  .container__11_dummy3{
    width: 50px;
    height: 5vh;
  }
  .container__11_picture{
    margin-bottom: 15px;
  }
}
/*##############################    section 11   #########################################*/

