/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/


.product_text__mobile-right{
    text-align: unset;
}
.product_text__mobile-left{
    text-align: unset;
}
.product_text-right{
    text-align: right;
}

.product_container_product{
   
    /* background-color: #161616; */
    color: white;
}

/*help header access sticky top =0   parent*/
.product_container__header_fefefef{
    overflow: hidden;
    top: 0px;
    /* height: 800vh; */
    width: 100%;
    z-index: 2;
    /* background-color: blue; */
}
.product__cotainer__scroll{
    overflow-y: scroll;
    height: calc(100vh - 60px) ;

}
.product__cotainer__scroll2{
    overflow-y: scroll;
    height: calc(100vh - 300px) ;

}
/*#######################################  Section 1    #################################################*/
.product_section1__container{
    /* background-color: rgb(130, 146, 118); */
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.product_section1__text-container{
    width: 40%;
    height: 80vh;
    /* background-color: rgb(163, 141, 163); */
}
.product_section1__picture-container{
    width: 50%;
    height: 80%;
    /* background-color: greenyellow; */
}
.product_section1__text-header{
    color: #27aa17;
    font-size:  5vmin;
    font-weight: bold;
    line-height: 6vmin;
}
.product_section1__text-sub-header{
    display: inline-block;
    color: black;
    font-size: 4vmin;
    font-weight: bold;
    line-height: 6vmin;
    border-bottom: 4px solid #27aa17;
    padding-bottom: 10px;
}

.product_section1__picture{
    width: 100%;
    height: auto;
    /* background-color: cadetblue; */
}
.product_section1__detail{
    color:#666666;
    line-height: 1.7em;
    font-size: 2.7vmin;
}
.product_section1__dummy{
    width: 50px;
    height: 20px;
}
/*largscreen*/
@media screen and (max-width: 1281px){

    .product_section1__picture-container{
        height: 80%;
        display: flex;
        justify-content: flex-start;
    }
}

/* meduim screen */
@media screen and (max-width: 1025px){

    .product_section1__container{
        justify-content: center;
    }
    .product_section1__picture-container{
        height: 50%;
        display: flex;
        justify-content: flex-start;
    }
}
/* mobile screen  */
@media screen and (max-width: 520px) {
    .product_section1__container{
        height: calc(100vh - 60px);
        /* background-color: brown; */
    }
    .product_container__header_fefefef{
        overflow: hidden !important;
    }
    .product_section1__text-sub-header{
        font-size: 5vmin;
    }
    .product_section1__container{
        width: calc(100% - 20px);
        height: calc(100vh);
        /* background-color: rgb(122, 148, 95); */
        flex-direction: column;
        padding: 10px;
        justify-content: center;
    }
    .product_section1__text-container{
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
    }
    .product_section1__picture-container{
        width: 100%;
        /* background-color: rgb(143, 143, 38); */
        margin-bottom: 15vh;
    }

    .product_section1__detail{
        font-size: 4vmin;
        line-height: 6vmin;
    }
}
/*##############################>>>>>>>>>>  Section 1    <<<<<<<<<<<<<<<<<<<<####################################*/


/*##############################>>>>>>>>>>  Section 2   <<<<<<<<<<<<<<<<<<<<####################################*/
.product_section2__container{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rgb(71, 107, 71); */
    flex-direction: column;
}
.product_section2__text-header-container{
    font-size: 2.8vmin;
    line-height: 3vmin;
    height: 30vh;
    width: 100%;
    /* background-color: hotpink; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

/* container 2 _ 1  */
.product_section2__container1{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    /* background-color: rgb(166, 205, 228); */
}
.product_section2__container1-left{
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 45%;
    height: 100%;
    /* background-color: wheat; */
    flex-direction: column;
}
.product_section2__container1-right{
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: space-evenly;
    width: 47%;
    height: 100%;
    /* background-color: gray; */
}
.product_section2__container-text{
    font-size: 2vmin;
    line-height: 4vmin;
    width: 80%;
    height: 50%;
    /* background-color: cyan; */
    padding: 30px;
}

.product_section4__header-right{
    display: flex;
    align-items:flex-end;
    justify-content: flex-end;
    color: #212121;
    font-size: 4vh;
    line-height: 5vh;
    font-weight: bold;

}

/* text  */
.product_section2__container1-left-text{
    font-size: 2vmin;
    line-height: 4vmin;
    text-align: right;
    color: #666666;
}
.product_section2__container1-right-text{
    font-size: 2vmin;
    line-height: 4vmin;
    text-align: left;
    color: #666666;
    line-height: 1.7em;


}
.product_dummy__text2-1{
    width: 20px;
    height: 20px;
}
/* container 2 _ 1  */


.product_section2__container2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 40%;
    width: 100%;
    /* background-color: rgb(202, 123, 238); */
}

.product_section2__text-header{
    padding-bottom: 20px;
    display: inline;
    border-bottom: 4px solid #27aa17;
    color: #212121;
    font-size: 5vmin;
    line-height: 3vmin;
    font-weight: bold;
   

}
/* image  */
.product_section2__image1{
    width: 25%;
    height: auto;
}

.product_text-left__section2{
    text-align: left;
}
@media screen and (max-width: 520px) {
    .product_section2__text-header{
        font-size: 7vmin;
    }
    /* top 1 left  */
    .product_section2__text-header-container{
        height: 20vh;
    }
    .product_section2__container1{
        /* background-color: chartreuse; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .product_section2__container2{
        width: 100%;
        height: auto;
        /* background-color: coral; */
        flex-direction: column;
    }
    .product_section2__container1-left{
        height: calc(100vh - 60px);
        width: 100%;
        align-items: center;
    }
    .product_section2__image1{
        width: 95%;
    }
    .product_section2__container-text{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        /* width: 80%; */
        /* background-color: coral; */
    }
    .product_section2__container1-left-text{
        font-size: 4vmin;
        line-height: 6vmin;
        text-align: center;
    }

    /* top 2  */
    .product_section2__image1{
        width: 80%;
    }
    .product_section2__container1-right{
        /* background-color: crimson; */
        width: 100%;
        height: calc(100vh - 60px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .product_section2__container1-right-text{
        font-size: 4vmin;
        line-height: 6vmin;
        text-align: center;
    }
}


/*##############################>>>>>>>>>>  Section 2   <<<<<<<<<<<<<<<<<<<<####################################*/


/*##############################>>>>>>>>>>  Section 3   <<<<<<<<<<<<<<<<<<<<####################################*/
.product_section3__container{
    width: 100%;
    height: calc(100%);
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: honeydew; */
    flex-direction: column;
}
.product_section3__text{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    
    width: 100%;
    height: 20%;
    /* background-color: blueviolet; */
}
.product_section3_text2{
    font-size: 2vmin;
    line-height: 3.7vmin;
    color: #666666;
}
.product_section3_text1{
    font-size: 4.5vh;
    margin-bottom: 2%;
    color: #212121;
    font-weight: bold;
}
.product_section3__piture{
    width: 50%;
    height: auto;
    /* background-color: yellowgreen; */
}
.product_section3__piture-container{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0;
    /* background-color: blanchedalmond; */
}
.product_section3__piture{
    width: 100%;
    height: auto;
}
.product_dummy3{
    width: 50px;
    height: 10%;
}
@media screen and (max-width: 520px) {
    .product_section3__piture-container{
        width: 100%;
    }
    .product_section3__text{
        margin-bottom: 50px;
    }
    .product_section3_text2{
        font-size: 4vmin;
        line-height: 6vmin;
    }

    .product_section3__piture{
        margin-bottom: 150px;
    }
}
/*##############################>>>>>>>>>>  Section 3   <<<<<<<<<<<<<<<<<<<<####################################*/

/*##############################>>>>>>>>>>  Section 4   <<<<<<<<<<<<<<<<<<<<####################################*/

.product_section4__container{
    text-align: center;
    width: 100%;
    height: calc(20%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: honeydew; */
    flex-direction: column;
}

/* top */
.product_section4__container-top{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.product_section4__header{
    color: #212121;
    font-weight: bold;
    font-size: 4vh;
}
.product_section4__text-detail{
    font-size: 2.5vmin;
    line-height: 3.7vmin;
    color: #666666;
    list-style: 10em;
}
.product_dummy__scrion4{
    width: 50px;
    height: 4vh;
}



/* bootom */
.product_section4__container-bottom{
    width: 80%;
    height: 65%;
    /* background-color: #f0a3a3; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}
.product_container4__left{
    width: 40%;
    height: 85%;
    /* background-color: aqua; */
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    
}
.product_text-align{
    text-align: right;
}
.product_section4__header-left{
    color: #212121;
    font-size: 4vh;
    line-height: 5vh;
    font-weight: bold;
    
}
.product_container4__right{
    width: 40%;
    height: 85%;
    /* background-color: rgb(81, 179, 179); */
}

.product_section4__picture{
    width: 100%;
    height: auto;
}


/*##############################>>>>>>>>>>  Section 4   <<<<<<<<<<<<<<<<<<<<####################################*/


/*##############################>>>>>>>>>>  Section 5  <<<<<<<<<<<<<<<<<<<<####################################*/

.product_section5__container{
    margin-bottom: 300px;
    width: calc(100% - 20px);
    height: calc(180% - 5px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: rgb(118, 204, 118); */
    flex-direction: column;
    padding:10px;
}
.product_section5__top-container{
    width: 90%;
    height: 50%;
    /* background-color: royalblue; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.product_section5__bottom-container{
    width: 90%;
    height: 50%;
    /* background-color: pink; */
    align-items: center;
    justify-content: space-evenly;
    display: flex;
}

.product_section5__container-picer{
    width: 35%;
    height: auto;
    /* background-color: plum; */
}
.product_section5__right-text{
    width: 35%;
    height: 90%;
    /* background-color: rgb(207, 59, 207); */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    text-align: right;
}

.product_section5__left-text{
    width: 30%;
    height: 90%;
    /* background-color: rgb(100, 198, 255); */
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
}

/* puicture */
.product_section5__picture{
    width: 100%;
    height: auto;
}
.product_dummy__section5{
    width: 50;
    height: 5vh;
}
@media screen and (max-width: 520px) {
    
    .product_section5__container{
        margin-bottom: 80px;
        height: 280vh;
        flex-direction: column;
        /* background-color: blueviolet; */

    }
    .product_section5__top-container{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .product_section5__right-text{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
    }
    .product_section5__bottom-container{
        flex-direction: column;
    }
    .product_section5__container-picer{
        width: 80%;
    }
    .product_section5__left-text{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
    }
    .product_section4__container{
        height: 30%;
    }
    .product_section4__container-top{
        width: 100%;
        height: 100%;
        margin-bottom: 60px;
    }
    .product_section4__text-detail{
        text-align: center;
        font-size: 4vmin;
        line-height: 6vmin;
    }
    .product_section4__text-detail br {
        display: none;
    }
   .product_section4__header{
       line-height: 7vh;
   }
}

/*##############################>>>>>>>>>>  Section 5   <<<<<<<<<<<<<<<<<<<<####################################*/

/*##############################>>>>>>>>>>  Section 6  <<<<<<<<<<<<<<<<<<<<####################################*/

.product_section6__container{
    text-align: center;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: rgb(78, 93, 114); */
    flex-direction: column;
}
.product_section6__top-container{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    height: 20%;
    margin-bottom: 40px;
    /* background-color: plum; */
}
.product_section6__bottom-container{
    width: 65%;
    height: 80%;
    /* background-color: rgb(126, 49, 126); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #666666;
}

.product_section6__container1{
    width: 100%;
    height: calc(22vh);
    /* background-color: rgb(65, 62, 50); */
    display: flex;
    align-items: center;
    justify-content: center;
}
.product__section6__dummy{
    width: 30px;
    height: 50px;
}
.product_center{
    justify-content: center;
}

.product_section6__container2{
    padding-left: 1%;
    padding-right: 1%;
    width: 45%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    -webkit-box-shadow: 0px 25px 44px -18px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 25px 44px -18px rgba(0,0,0,0.4);
    box-shadow: 0px 25px 44px -18px rgba(0,0,0,0.4);

}

.product_dummy__section6{
    width: 20px;
    height: 1em;
}

.product_section6__font-header{
    font-weight: bold;
    color:  #27aa17;
    line-height: 10vmin;
    font-size: 3.5vmin;
}
.product_section6__text-detail{
    color: black;
    font-size: 2vmin;
    line-height: 3vmin;
}

@media screen and (max-width: 520px) {
    .product_section6__text-detail br {
        display: none;
    }
    .product__section6__dummy{
        width: 0px;
        height: 0px;
        display: none;
    }
    .product_section6__font-header{
        font-size:  5vmin;
    }
    .product_section6__container{
        height: 140vh;
        width: 100%;
        /* background-color:coral ; */
    }
    .product_section6__container1{
        /* background-color: blueviolet; */
        height: 40vh;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }
    .product_section6__container2{
        width: 95%;
        /* background-color: cyan; */
        padding-bottom: 10px;
    }
    .product_section6__bottom-container{
        width: 100%;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product_section6__container2{
        margin-bottom: 50px;
        height: 47%;
    }
    .product_section6__top-container{
        width: 95%;
    }
    .product_section6__text-detail{
        font-size: 4vmin;
        line-height: 5vmin;
        color: #666666;
    }
}
/*##############################>>>>>>>>>>  Section 6   <<<<<<<<<<<<<<<<<<<<####################################*/


/*##############################>>>>>>>>>>  Section 7   <<<<<<<<<<<<<<<<<<<<####################################*/
.product_section7__container{
    margin-top: 50px;
    /* background-color: lavender; */
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline:none;
    /* background-color: rgb(107, 85, 128); */
    
}

.product_section7_text_container1 a{
    text-decoration: none;
    font-size: 2vmin;
    color: black;
}
.button_product {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 16px 32px;
    border-radius: 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    margin-top: 50px;

  }
.button_product1 {
    background-color: white; 
    
    border: 2px solid #008CBA;
  }
  .button_product1:hover {
    background-color: #008CBA;
    color: rgb(255, 255, 255);
  }
  .button_product1:hover a{
    color: white;
  }
.product_section7__container1{
    width: 100%;
    height: 50vh;
    display: flex;
    background: rgb(68,202,69);
    background: linear-gradient(90deg, rgba(68,202,69,1) 0%, rgba(4,66,5,1) 100%);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* z-index: 9; */
    
}
.product_section7_text_container1{
    width: 60%;
    height: 70%;
    text-align: center;
    /* background-color: #27aa17; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.product_section7__text-header{
    font-weight: bold;
    color:  white;
    line-height: 8vmin;
    font-size: 3vmin;
}
.product_section7__text{
    color: white;
    font-size: 2.5vmin;
    line-height: 6vmin;
}
@media screen and (max-width: 520px) {
    .button_product{
        margin-top: 50px;
        padding: 12px 18px;
    }
    .product_section7_text_container1 a{
        text-decoration: none;
        font-size:4vmin;
    }
    .button__product{
        width: 40%;
        
    }
    .product_section7__container1{
        height: 40vh;
    }
    .product_section7_text_container1{
        width: 90%;
    }
    .product_section7__text-header{
        line-height: 9vmin;
        font-size: 5vmin;
    }
    .product_section7__text{
        font-size: 3vmin;
        line-height: 6vmin;
    }
    .product_section7__button span{
        font-size: 2.8vmin;
    }
}



.product_section7__container2{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 30vh;
    /* background-color: rgb(63, 202, 202); */
    flex-direction: column;
}
.product_section7__title{
    height: 5vh;
    /* background-color: coral; */
    width: 5%;
    text-align: center;

}
.product_section7__title span h1{
    border-bottom: 4px solid #27aa17;
    padding-bottom: 15px;
    font-weight: bold;
    line-height: 3vmin;
    font-size: 3vmin;
    color: black;
}
.product_section7__customer{
    width: 60%;
    height: 40%;
    /* background-color: thistle; */
}

.product_carousel-item-padding-40-px{
    width: 50%;
    height: 100%;
}

@media screen and (max-width: 520px) {
    .product_section7__title span h1{
        line-height: 6vmin;
        font-size: 6vmin;
    }
    .product_section7__title{
        width: 20%;
    }
    .product_carousel-item-padding-40-px{
        width: 100%;
        height: 100%;
    }
}






.product_section7__container3{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* background-color: rgb(104, 167, 120); */
}
.product_section7__titile{
    display: flex;
    justify-content: center;
}
.product_section7__titile span{
    padding-bottom: 15px;
    border-bottom: 4px solid #27aa17;
}
.product_section7__titile span h1{
    font-size: 3vmin;
    line-height: 6vmin;
    color: black;
    font-weight: bold;
}

/* all logo 11 items */
.gridLoGO{
    /* background-color: rgb(145, 91, 91); */
    display: grid;
    grid-template-columns: repeat(6,120px);
    grid-template-rows: repeat(2,120px);
    grid-template-areas: 
    "item1 item2 item3 item4 item5 item6 "
    "item7 item8 item9 item10 item11 item12" ;
    gap: 10px 10px;
    width: 100%;
    justify-content: center;

}

.product_section7_item1{
    grid-area: item1;
    /* background-color: red; */
    align-self: center;
    justify-self: center;
}
.product_section7_item1 img{
    width: 100%;
    height: auto;
}

.product_section7_item2{
    grid-area: item2;
    /* background-color: rgb(30, 109, 70); */
    align-self: center;
    justify-self: center;
}
.product_section7_item2 img{
    width: 100%;
    height: auto;
}

.product_section7_item3{
    grid-area: item3;
    /* background-color: rgb(112, 65, 65); */
    align-self: center;
    justify-self: center;
}
.product_section7_item3 img{
    width: 100%;
    height: auto;
}


.product_section7_item4{
    grid-area: item4;
    /* background-color: rgb(252, 243, 243); */
    align-self: center;
    justify-self: center;
}
.product_section7_item4 img{
    width: 100%;
    height: auto;
}


.product_section7_item5{
    grid-area: item5;
    /* background-color: rgb(94, 59, 150); */
    align-self: center;
    justify-self: center;
}
.product_section7_item5 img{
    width: 100%;
    height: auto;
}


.product_section7_item6{
    grid-area: item6;
    /* background-color: rgb(109, 55, 95); */
    align-self: center;
    justify-self: center;
}
.product_section7_item6 img{
    width: 100%;
    height: auto;
}


.product_section7_item7{
    grid-area: item7;
    /* background-color: rgb(120, 211, 147); */
    align-self: center;
    justify-self: center;
}
.product_section7_item7 img{
    width: 100%;
    height: auto;
}


.product_section7_item8{
    grid-area: item8;
    /* background-color: rgb(138, 127, 127); */
    align-self: center;
    justify-self: center;
}
.product_section7_item8 img{
    width: 100%;
    height: auto;
}


.product_section7_item9{
    grid-area: item9;
    /* background-color: rgb(116, 41, 51); */
    align-self: center;
    justify-self: center;
}
.product_section7_item9 img{
    width: 100%;
    height: auto;
}

.product_section7_item10{
    grid-area: item10;
    /* background-color: rgb(99, 112, 111); */
    align-self: center;
    justify-self: center;
}
.product_section7_item10 img{
    width: 100%;
    height: auto;
}


.product_section7_item11{
    grid-area: item11;
    /* background-color: rgb(56, 53, 53); */
    align-self: center;
    justify-self: center;
}
.product_section7_item11 img{
    width: 100%;
    height: auto;
}
@media only screen and (max-width: 420px)  {
    .product_section7__titile span h1{
        font-size: 6vmin;
        line-height: 6vmin;
    }
    .gridLoGO{
        margin-top: 20px;
    grid-gap: 30px 0px;
    grid-template-columns: repeat(2,150px);
    grid-template-rows: repeat(7,1fr);
    grid-template-areas: 
        "item1 item2"
        "item3 item4" 
        "item5 item6 "
        "item7 item8" 
        "item9 item10" 
        "item11 item12";
    }

}
@media only screen and (min-width: 421px) and (max-width: 767px) {

    .gridLoGO{
        align-items: center;
        grid-gap: 30px 30px;
        grid-template-columns: repeat(3,150px);
        grid-template-rows: repeat(4,1fr);
        /* background-color: rgb(123, 185, 123); */
        grid-template-areas:
        "item1 item2 item3"
        "item4 item5 item6" 
        "item7 item8 item9" 
        "item10 item11 item12";
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){

    .gridLoGO{

        grid-template-areas: 
        "item1 item2 item3 item4 item5 item6 "
        "item7 item8 item9 item10 item11 item12" ;
    }

}
@media only screen and (min-width: 1024px) and (max-width: 1365px) {

}


/*##############################>>>>>>>>>>  Section 7   <<<<<<<<<<<<<<<<<<<<####################################*/
