.contact__input{
    width: 400px;
    height: 30px;
    border-radius: 30px;
    outline: none;
    border: none;
    border: solid 2px #ccc;
    font-size: 2vmin;
    text-indent: 15px;
    padding: 10px;
}



.contact__textarea{
    padding: 10px;
    text-indent: 15px;
    width: 390px;
    border-radius: 30px;
    outline: none;
    padding-top: 15px;
    border: none;
    border: solid 2px #ccc;
    font-size: 2vmin;
}

.contact__buttom{
    width: 150px;
    height: 40px;
    background-color: #27aa17;
    color: white;
    border-color: white;
    border-radius: 20px;
    box-shadow: none;
    outline: none;
    border: none;
}
.contact__buttom:hover{
    background-color: #248617;
    cursor: pointer;
}

.contact__main{
    text-align: center;

}

input:focus{
    border: solid 2px #27aa17;
}
textarea:focus{
    border: solid 2px #27aa17;
}
@media  screen and (max-width: 500px){
    .contact__input{
        width: 300px;
        height: 30px;
        padding: 5px;
        border-radius: 30px;
        outline: none;
        padding-left: 15px;
    }
    .contact__textarea{
        width: 300px;
        border-radius: 30px;
        padding-left: 15px;
        outline: none;
        padding-top: 15px;
    }
    
    .contact__buttom{
        width: 150px;
        height: 40px;
        background-color: #27aa17;
        color: white;
        border-color: white;
        border-radius: 20px;
        box-shadow: none;
        outline: none;
    }
    .contact__buttom:hover{
        background-color: #248617;
    
    }
    
    .contact__main{
        text-align: center;
    
    }
}
.googlemap-iframe{
    width: 100%;
    height: 100%;
}
