#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.Pricing__buttom{
  /* style={{width:"100%",display:'flex',alignItems:'center',justifyItems:'center'}} */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  
}
.Pricing__buttom a{
  text-decoration: none;
}
.button_pricing{
  border: none;
  color: white;
  padding: 10px 25px;
  border-radius: 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  margin-top: 50px;
  border: 1px solid transparent
}
.button_pricing1 {
  background-color: #27aa17; 
  color: white;
  
}
.button_pricing1:hover {
  background-color: #1e6d13;
  color: white;
  border: 1px solid white
}
.button_pricing1 a{
  color: white;
}

.pricing__main{
  overflow: hidden !important;
  width: 100%;
  margin:  0 auto;
  font-family: "Sarabun", "sans-serif" !important;
}
.pricing__header_text{
  margin-bottom: 100px;
}
.pricing__header_text div{
  margin-top: 50px;
}

.pricing__cotainer__scroll{
  overflow-y: scroll;
  height: calc(100vh - 60px) ;
}
.pricing__cotainer__scroll2{
  overflow-y: scroll;
  height: calc(100vh - 300px) ;
}

.pricing__header_text button a{
  text-decoration: none;
  color: black;
}

.pricing__header_text{
  padding-left: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  margin-top: 100px;
  /* background-color: cadetblue; */
  flex-direction: column;
  height: 200px;
}
.pricing__header_text div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.pricing__header_text div h1{
  font-size: 4vmin;
  line-height: 6vmin;
  font-weight: bold;
}
.pricing__header_text div h2 {
  font-size: 2.5vmin;
  line-height: 6vmin;

}

#customers td, #customers th {
  /* border: 1px solid #ddd; */
  padding: 15px;
}



#customers tr{
  text-align: center;
  font-size: 2vmin;
}

#customers tr:hover {
  background-color: #ddd;

}
#customers tr td{
  line-height: 32px;
}

#customers th:nth-child(2){
  padding-left: 50px;
  padding-right: 50px; 
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  font-size: 3vmin;
}
#customers th:nth-child(3){
  padding-left: 50px;
  padding-right: 50px; 
  padding-bottom: 12px;
  text-align: center;
  background-color: #a73581;
  color: white;
  font-weight: bold;
  font-size: 3vmin;
}
#customers th:nth-child(4){
  padding-bottom: 12px;
  text-align: center;
  background-color: #aab419;
  color: white;
  padding-left: 50px;
  padding-right: 50px; 
  font-weight: bold;
  font-size: 3vmin;
}

.pricing__table__container{
  margin-top: 100px;
  width: 60%;
  margin-left:auto ;
  margin-right: auto;
}
.pricing__header{
    width: 100%;
    top: 0px;
    margin: 0px;
    z-index: 2;
    /* background-color: #a73581; */
}
#customers{
  border-bottom: 1px solid #c2c1c1;
}
footer{
  margin-top: 30px;
}
footer h1{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 2.2vmin;
  width: 58%;
  margin: 0 auto;
  text-align: left;
  line-height: 6vmin;
}
footer h2{
  margin-top:55px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.8vmin;
  width: 58%;
  margin: 0 auto;
  text-align: left;
  line-height: 6vmin;
}
@media only screen and (max-width: 620px){
  .table__text__55{
    font-size: 3.5vmin;
  }
  .table__text__56{
    font-size: 5vmin;
    font-weight: bold;
  }
  footer h1{
    font-size: 3vmin;
  }
  footer h2 {
    font-size: 3.1vmin;
  }
  .pricing__header_text div h1{
    font-size: 5vmin;
  }
  .pricing__header_text div h2{
    font-size: 3.5vmin;
  }
  .pricing__header_text div h2 br{
    display: none;
  }
  footer h1{
    width: 95%;
  }
  footer h2{
    width: 95%;
    margin-bottom: 10px;
  }
  .pricing__header_text{
    margin-top: 0px;

  }
  .pricing__texttextetx{
    font-size: 3vmin;
    line-height: 6vmin;
  }
  #customers tr td h1{
    font-style: 2.1vmin;
  }
  .pricing__table__container{
    margin-top: 0px;
    width: 100%;
    margin-right: 40%;
  }
  #customers th:nth-child(2){
    padding-left: 10px;
    padding-right: 10px; 
    font-size: 3vmin;
  }
  #customers th:nth-child(3){
    padding-left: 10px;
    padding-right: 10px; 
    font-weight: bold;
    font-size: 3vmin;
  }
  #customers th:nth-child(4){
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 10px; 
    font-size: 3vmin;
  }
}
